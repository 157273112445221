import {
  IGetRetireRequestDTO,
  IGetRetireResponseDTO,
  IRetireRECs,
} from "@/models/Retire";
import { ErrorResponse } from "@/services/axios/error";
import { IResponse } from "@/services/axios/entities";
import { ElMessage } from "element-plus/lib/components/message";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { RetireAPI } from "@/services/retire";
import store from "@/store";

const name = "RetireModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class RetireModule extends VuexModule {
  private errorRetireRECs = {} as ErrorResponse;
  private isLoadingRetireRECs = false;

  private errorGetRetireRECs = {} as ErrorResponse;
  private isLoadingGetRetireRECs = false;
  private dataGetRetireRECs = [] as IGetRetireResponseDTO[][];

  get isLoadingRetireRECsGetter() {
    return this.isLoadingRetireRECs;
  }

  @Mutation
  setLoadingRetireRECs(isLoading: boolean) {
    this.isLoadingRetireRECs = isLoading;
  }
  @Mutation
  retireRECsSuccess() {
    this.errorRetireRECs = {} as ErrorResponse;
    this.isLoadingRetireRECs = false;
    ElMessage.success("Successfully Retired RECs!");
  }
  @Mutation
  retireRECsFailure(error: ErrorResponse) {
    this.errorRetireRECs = error;
    this.isLoadingRetireRECs = false;
    ElMessage.error(error);
  }
  @Action({ rawError: true })
  async retireRECs(data: IRetireRECs): Promise<IResponse> {
    const serviceRenewableOptionAPI = new RetireAPI();
    const { error } = await serviceRenewableOptionAPI.retireRECs(data);

    if (error) {
      this.retireRECsFailure(error);
      return { error };
    } else {
      this.retireRECsSuccess();
      return { data };
    }
  }

  @Mutation
  setLoadingGetRetireRECs(isLoading: boolean) {
    this.isLoadingRetireRECs = isLoading;
  }
  @Mutation
  getRetireRECsSuccess() {
    this.errorRetireRECs = {} as ErrorResponse;
    this.isLoadingRetireRECs = false;
  }
  @Mutation
  getRetireRECsFailure(error: ErrorResponse) {
    this.errorGetRetireRECs = error;
    this.isLoadingGetRetireRECs = false;
    ElMessage.error(error);
  }
  @Action({ rawError: true })
  async getRetireRECs(payload: IGetRetireRequestDTO) {
    const serviceRenewableOptionAPI = new RetireAPI();
    const { error } = await serviceRenewableOptionAPI.getRetireRECs(payload);
    if (error) {
      this.retireRECsFailure(error);
      return { error };
    } else {
      this.getRetireRECsSuccess();
    }
  }
}
export default getModule(RetireModule);
